import request from '@/utils/request'


// 查询预售活动商品配置列表
export function listPresaleActivityProd(query) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/list',
    method: 'get',
    params: query
  })
}

// 查询预售活动商品配置分页
export function pagePresaleActivityProd(query) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/page',
    method: 'get',
    params: query
  })
}

// 查询预售活动商品配置详细
export function getPresaleActivityProd(data) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/detail',
    method: 'get',
    params: data
  })
}

// 新增预售活动商品配置
export function addPresaleActivityProd(data) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/add',
    method: 'post',
    data: data
  })
}

// 修改预售活动商品配置
export function updatePresaleActivityProd(data) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/edit',
    method: 'post',
    data: data
  })
}

// 删除预售活动商品配置
export function delPresaleActivityProd(data) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/delete',
    method: 'post',
    data: data
  })
}

// 审核预售活动商品配置
export function auditPresaleActivityProd(data) {
  return request({
    url: '/presaleActivityProd/presaleActivityProd/audit',
    method: 'post',
    data: data
  })
}