<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">

      <!--<a-form-model-item prop="shopId" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.店铺id')}}<template slot="title">{{$t('预售活动商品配置.店铺id')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.shopId"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.店铺id')" />-->
      <!--</a-form-model-item>-->

      <a-form-model-item prop="acName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.活动名称')}}<template slot="title">{{$t('预售活动商品配置.活动名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.acName" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.活动名称')" />
      </a-form-model-item>
      <a-form-model-item prop="acStartTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.活动开始时间')}}<template slot="title">{{$t('预售活动商品配置.活动开始时间')}}</template>
          </a-tooltip>
        </span>
        <!--<a-date-picker style="width: 100%" v-model="form.acStartTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
        <a-date-picker style="width: 100%" v-model="form.acStartTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="acEndTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.活动结束时间')}}<template slot="title">{{$t('预售活动商品配置.活动结束时间')}}</template>
          </a-tooltip>
        </span>
        <!--<a-date-picker style="width: 100%" v-model="form.acEndTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
        <a-date-picker style="width: 100%" v-model="form.acEndTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <!--<a-form-model-item prop="goodsId" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.商品id')}}<template slot="title">{{$t('预售活动商品配置.商品id')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.goodsId"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.商品id')" />-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="goodsName" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.商品名称')}}<template slot="title">{{$t('预售活动商品配置.商品名称')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.goodsName" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.商品名称')" />-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="goodsPicture" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.商品图片')}}<template slot="title">{{$t('预售活动商品配置.商品图片')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.goodsPicture" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.商品图片')" />-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="goodsPrice" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.商品原价')}}<template slot="title">{{$t('预售活动商品配置.商品原价')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.goodsPrice"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.商品原价')" />-->
      <!--</a-form-model-item>-->
      <a-form-model-item prop="acPrice" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.预售商品售卖价格')}}<template slot="title">{{$t('预售活动商品配置.预售商品售卖价格')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.acPrice"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.预售商品售卖价格')"  @change="limitMarketAcPrice()"/>
      </a-form-model-item>
      <a-form-model-item prop="prePrice" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.支付定金价格')}}<template slot="title">{{$t('预售活动商品配置.支付定金价格')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.prePrice"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.支付定金价格')"  @change="limitMarketPrice()"/>
      </a-form-model-item>

      <a-form-model-item prop="processDesc" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.流程说明')}}<template slot="title">{{$t('预售活动商品配置.流程说明')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.processDesc"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.流程说明')"/>
      </a-form-model-item>





      <!--<a-form-model-item prop="endPrice" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.支付尾款价格(预售商品售卖价格-支付定金价格)')}}<template slot="title">{{$t('预售活动商品配置.支付尾款价格(预售商品售卖价格-支付定金价格)')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.endPrice"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.支付尾款价格(预售商品售卖价格-支付定金价格)')" />-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="checkStatus" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.审核状态  1:待审核 2:审核未通过 3:审核通过 4:活动已结束')}}<template slot="title">{{$t('预售活动商品配置.审核状态  1:待审核 2:审核未通过 3:审核通过 4:活动已结束')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="auditMessage" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.审核意见')}}<template slot="title">{{$t('预售活动商品配置.审核意见')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.auditMessage" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.审核意见')" />-->
      <!--</a-form-model-item>-->
      <!--<a-form-model-item prop="completeTotal" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.总完成数量')}}<template slot="title">{{$t('预售活动商品配置.总完成数量')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.completeTotal"  :placeholder="$t('通用.输入.请输入')+$t('预售活动商品配置.总完成数量')" />-->
      <!--</a-form-model-item>-->
      <a-form-model-item prop="acStartPriceTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('预售活动商品配置.尾款付款开始时间')}}<template slot="title">{{$t('预售活动商品配置.尾款付款开始时间')}}</template>
          </a-tooltip>
        </span>
        <!--<a-date-picker style="width: 100%" v-model="form.acStartPriceTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>-->
        <a-date-picker style="width: 100%" v-model="form.acStartPriceTime" valueFormat="YYYY-MM-DD HH:mm:ss" show-time allow-clear/>
      </a-form-model-item>
      <!--<a-form-model-item prop="remark" >-->
        <!--<span slot="label" >-->
          <!--<a-tooltip>-->
            <!--{{$t('预售活动商品配置.备注')}}<template slot="title">{{$t('预售活动商品配置.备注')}}</template>-->
          <!--</a-tooltip>-->
        <!--</span>-->
        <!--<a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
      <!--</a-form-model-item>-->



      <!--选择商品-->
      <a-form-model-item :label="$t('通用.文本.选择商品')" prop="goodsId">
        <a-button type="dashed" @click="openProdSelect" danger>
          <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
        </a-button>
      </a-form-model-item>
      <!-- 数据展示 -->
      <a-table
        :loading="goodsLoading"
        :scroll="{ x: '160%' }"
        rowKey="id"
        :size="tableSize"
        :columns="goodsColumns"
        :data-source="form.goodsList"
        :pagination="false"
        :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>

        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
        </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
      </a-table>

      <!--选择商品-->







      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>

      <!--选择商品-->
      <radio-goods-select-form ref="RadioGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></radio-goods-select-form>
      <!--选择商品-->

    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPresaleActivityProd, addPresaleActivityProd, updatePresaleActivityProd } from '@/api/presaleActivityProd/presaleActivityProd'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

//选择商品
import RadioGoodsSelectForm from "@/views/zb/seckill/modules/RadioSelectForm";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";
//选择商品

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    //选择商品
    RadioGoodsSelectForm
    //选择商品
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      //选择商品
      categoryList:[],
      goodsColumns: [
        /*{
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },*/
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 180,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
      ],
      //选择商品
      // 表单参数
      form: {
        //选择商品
        goodsList: [],
        //选择商品
        id: null,

        shopId: null,

        acName: null,

        acStartTime: null,

        acEndTime: null,

        goodsId: null,

        goodsName: null,

        goodsPicture: null,

        goodsPrice: null,

        acPrice: null,

        prePrice: null,

        endPrice: null,

        checkStatus: '0',

        auditMessage: null,

        completeTotal: null,

        acStartPriceTime: null,

        createTime: null,

        remark: null,

        processDesc: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopId: [
          { required: true, message: this.$t('预售活动商品配置.店铺id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        acName: [
          { required: true, message: this.$t('预售活动商品配置.活动名称')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        acStartTime: [
          { required: true, message: this.$t('预售活动商品配置.活动开始时间')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        acEndTime: [
          { required: true, message: this.$t('预售活动商品配置.活动结束时间')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        acStartPriceTime: [
          { required: true, message: this.$t('预售活动商品配置.尾款付款开始时间')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        // goodsId: [
        //   { required: true, message: this.$t('预售活动商品配置.商品id')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        // ],
        acPrice: [
          { required: true, message: this.$t('预售活动商品配置.预售商品售卖价格')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        prePrice: [
          { required: true, message: this.$t('预售活动商品配置.支付定金价格')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        endPrice: [
          { required: true, message: this.$t('预售活动商品配置.支付尾款价格(预售商品售卖价格-支付定金价格)')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        checkStatus: [
          { required: true, message: this.$t('预售活动商品配置.审核状态  1:待审核 2:审核未通过 3:审核通过 4:活动已结束')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    //选择商品
    this.getCateList();
    this.getBrandList();
    //选择商品
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        //选择商品
        goodsList: [],
        //选择商品
        id: null,
        shopId: null,
        acName: null,
        acStartTime: null,
        acEndTime: null,
        goodsId: null,
        goodsName: null,
        goodsPicture: null,
        goodsPrice: null,
        acPrice: null,
        prePrice: null,
        endPrice: null,
        checkStatus: '0',
        auditMessage: null,
        completeTotal: null,
        acStartPriceTime: null,
        createTime: null,
        remark: null,
        processDesc: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPresaleActivityProd({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },

    //选择商品
    indexGoodsModalSelect(records) {
      this.form.goodsId = records[0].id
      this.form.goodsList = records
      console.log('商品选择了',this.form.goodsList)
    },
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },
    //选择商品

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updatePresaleActivityProd(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addPresaleActivityProd(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },

    limitMarketPrice() {
      let val = this.form.prePrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.prePrice = val
    },

    limitMarketAcPrice() {
      let val = this.form.acPrice.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1');
      if (val == null || val == undefined || val == '' || val < 0) {
        val = ''
      }
      if (val > 21474836) {
        val = 21474836
      }
      this.form.acPrice = val
    },

    //选择商品
    openProdSelect() {
      console.log('打开商品选择框,单选模式')
      this.$refs.RadioGoodsSelectForm.openGoods(undefined, [this.form.goodsId]);
    },

    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },
    getCateList() {
      listCategory().then(response => {
        this.categoryList = response.data;
      const cate = { id: "0", categoryName: this.$t('通用.文本.根节点'), children: [] }
      cate.children = this.handleTree(response.data, 'id','pid')
      this.cateOptions.push(cate)
    })
    },
    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
    })
    },
    //选择商品

  }
}
</script>
